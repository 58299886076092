'use client';

import React from 'react';
import Container from '@src/components/Shared/Container';
import SectionHeader from '@src/components/Shared/SectionHeader';
import ParagraphSeparator from '@src/components/Shared/ParagraphSeparator';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import RenderButtons from '@src/components/Shared/RenderButtons';
import InlineSVG from '@src/components/Shared/InlineSVG';
import useHumanTrakTilesAnimate from './useHumanTrakTilesAnimate';
import { HumanTrakVideoPropTypes } from './HumanTrakVideo.types';
export default function HumanTrakVideo({
  data,
  id
}: HumanTrakVideoPropTypes) {
  const {
    containerRef
  } = useHumanTrakTilesAnimate();
  return <div id={id} className='' data-sentry-component="HumanTrakVideo" data-sentry-source-file="HumanTrakVideo.tsx">
      <Container className='py-[7.4rem] md:flex md:flex-col' data-sentry-element="Container" data-sentry-source-file="HumanTrakVideo.tsx">
        <SectionHeader className='z-[101] justify-center md:mb-[3.4rem] lg:pb-[unset]' pretitle={data?.preTitle} title={data?.title} titleClassName='max-w-[71.6rem] text-[2.8rem] xl:text-[4.8rem] mb-[2.4rem] leading-[3.2rem] md:leading-[4.6rem] xl:leading-[5rem] font-[300]' align='mobile-left' data-sentry-element="SectionHeader" data-sentry-source-file="HumanTrakVideo.tsx">
          <>
            <ParagraphSeparator data-sentry-element="ParagraphSeparator" data-sentry-source-file="HumanTrakVideo.tsx">
              <RenderBodyCopy className='2xl:max-w-[78.2rem]' bodyCopy={data?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HumanTrakVideo.tsx" />
            </ParagraphSeparator>
            <RenderButtons className='justify-center' items={data?.buttonCollection?.items} data-sentry-element="RenderButtons" data-sentry-source-file="HumanTrakVideo.tsx" />
          </>
        </SectionHeader>

        <div ref={containerRef} className='relative mt-[3rem] md:mt-[-5rem] xl:mt-[-10rem]'>
          <video src={data?.mediaPrimary?.url} className='lg:mb-[unset]' muted autoPlay playsInline />
          {/* Camera and tripod description desktop and mobile */}
          <InlineSVG src={data?.extraAssetsCollection?.items?.[0]?.media?.url} className='humantrak-tile-animate !absolute left-[-2%] top-[52%] hidden w-[22rem] opacity-0 lg:block 2xl:w-[30rem]' data-sentry-element="InlineSVG" data-sentry-source-file="HumanTrakVideo.tsx" />
          <InlineSVG src={data?.extraAssetsCollection?.items?.[1]?.media?.url} className='humantrak-tile-animate !absolute left-[-4%] top-[14%] w-[10rem] opacity-0 xsm:left-[2%] xsm:top-[16%] sm:left-[2%] sm:w-[12rem] md:left-[4%] md:top-[18%] md:w-[15rem] lg:hidden' data-sentry-element="InlineSVG" data-sentry-source-file="HumanTrakVideo.tsx" />
          {/* Hard case travel description */}
          <InlineSVG src={data?.extraAssetsCollection?.items?.[2]?.media?.url} className='humantrak-tile-animate !absolute left-[59%] top-[30%] w-[15rem] opacity-0 sm:w-[17rem] md:w-[20rem] lg:left-[60%] lg:top-[33%] lg:w-[28rem] 2xl:w-[36rem]' data-sentry-element="InlineSVG" data-sentry-source-file="HumanTrakVideo.tsx" />
          {/* Custom laptop description */}
          <InlineSVG src={data?.extraAssetsCollection?.items?.[3]?.media?.url} className='humantrak-tile-animate !absolute left-[70%] top-[38%] w-[12rem] opacity-0 sm:left-[73%] sm:top-[39%] sm:w-[14.5rem] md:left-[73%] md:top-[40%] md:w-[19rem] lg:left-[75%] lg:top-[41%] lg:w-[26rem] 2xl:w-[34rem] 3xl:top-[44%]' data-sentry-element="InlineSVG" data-sentry-source-file="HumanTrakVideo.tsx" />
          {/* Wireless keyboard description */}
          <InlineSVG src={data?.extraAssetsCollection?.items?.[4]?.media?.url} className='humantrak-tile-animate !absolute left-[60%] top-[85%] w-[9rem] opacity-0 sm:w-[11rem] md:w-[15rem]  lg:top-[85%] lg:w-[20rem] 2xl:left-[65%] 2xl:w-[25rem]' data-sentry-element="InlineSVG" data-sentry-source-file="HumanTrakVideo.tsx" />
        </div>
      </Container>
    </div>;
}